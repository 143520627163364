/**
 * 是否为外部链接
 * @param {any} url 链接
 * @returns {boolean}
 */
export const isExternalLink = url => /^(https?:|mailto:|tel:)/.test(url)

/**
 * 验是否为手机端
 * @returns {boolean}
 */
export function isMobile() {
  return /Mobi|Android|iPhone|SymbianOS|Windows Phone|iPad|iPod/i.test(navigator.userAgent)
}

/**
 * 是否为IOS
 * @returns {boolean}
 */
export function isIOS() {
  return /iPhone|iPad/i.test(navigator.userAgent)
}
