/**
 * 分页相关
 */

/**
 * 分页状态
 */
export const PagingStatusEnum = {
  // 正在加载
  LOADING: 'loading',
  // 加载更多
  MORE: 'more',
  // 已全部加载完成
  FINISHED: 'finished',
  // 加载失败
  ERROR: 'error',
}
