<template>
  <div class="box">
    <topMenu :index="1"></topMenu>
    <div class="content">
      <div class="menu">
        <div
          class="menu-item"
          v-for="item in menuList"
          :key="item.id"
          :class="{ active: param.areaId === item.id }"
          @click="select(item.id)"
        >
          {{ item.title }}
        </div>
      </div>

      <div class="right-main">
        <div
          class="scroll-view"
          :infinite-scroll-disabled="menuLoading"
          :infinite-scroll-distance="20"
          :infinite-scroll-immediate="false"
          v-infinite-scroll="getList"
        >
          <ul class="list">
            <li v-for="item in list" :key="item.id" class="list-item">
              <div class="logo">
                <img v-if="item.logo" :src="config.ossUrl + item.logo" />
              </div>
              <div class="info">
                <div v-if="item.title" class="text">展商：{{ item.title }}</div>
                <div v-if="item.phone" class="text">电话：{{ item.phone }}</div>
                <div v-if="item.email" class="text">邮箱：{{ item.email }}</div>
                <div v-if="item.website" class="text">
                  网址：
                  <template v-if="isExternalLink(item.website)">
                    <a :href="item.website" target="_blank">{{ item.website }} </a>
                  </template>
                  <template v-else>
                    {{ item.website }}
                  </template>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import config from '../config/config'
import topMenu from '@/components/topMenu.vue'
import Footer from '../components/Footer.vue'
import { arealist, traderlist } from '../api/index'
import { PagingStatusEnum } from '@/enums/pagingEnum'
import { isExternalLink } from '@/utils/is'

export default {
  components: {
    topMenu,
    Footer,
  },
  data() {
    return {
      config,
      menuLoading: true,
      menuList: [],
      param: {
        pageNum: 1,
        pageSize: 12,
        areaId: '',
      },
      list: [],
      total: 0,
      pagingStatus: '',
    }
  },
  computed: {
    pagingDisabled() {
      return [PagingStatusEnum.LOADING, PagingStatusEnum.FINISHED].includes(this.pagingStatus)
    },
  },
  methods: {
    isExternalLink,
    getMenuList() {
      arealist().then(res => {
        const rows = res.rows
        if (res.code === 200 && rows.length) {
          this.menuList = rows
          this.param.areaId = rows[0].id
          this.menuLoading = false
          this.getList()
        }
      })
    },

    getList() {
      if (this.pagingDisabled) return

      this.pagingStatus = PagingStatusEnum.LOADING

      const { pageNum } = this.param
      traderlist(this.param)
        .then(res => {
          if (res.code === 200) {
            this.list = pageNum === 1 ? res.rows : [...this.list, ...res.rows]
            this.total = res.total
            this.param.pageNum++

            this.pagingStatus = this.list.length < this.total ? PagingStatusEnum.MORE : PagingStatusEnum.FINISHED
          }
        })
        .catch(() => {
          this.pagingStatus = PagingStatusEnum.ERROR
        })
    },

    resetPaging() {
      this.param.pageNum = 1
      this.pagingStatus = ''
      this.list = []
      this.total = 0
      this.getList()
    },

    select(id) {
      this.param.areaId = id
      this.resetPaging()
    },
  },
  mounted() {
    this.getMenuList()
  },
}
</script>

<style lang="less" scoped>
.box {
  background-color: #f5f5f5;
  overflow-x: hidden;
}
.content {
  display: flex;
  position: relative;
  width: 1100px;
  height: 680px;
  margin: 30px auto;

  .menu {
    background-color: #fefefe;
    max-width: 200px;
    padding: 30px 40px;
    box-sizing: border-box;
    flex-shrink: 0;
    margin-right: 15px;

    .menu-item {
      display: flex;
      align-items: center;
      line-height: 1.2;
      cursor: pointer;
      margin-bottom: 30px;

      &::before {
        content: '';
        width: 15px;
        height: 15px;
        background: url('~@/assets/gxImg/home/arrows.png') no-repeat center / contain;
        margin-right: 10px;
      }

      &.active {
        color: #1b76e8;

        &::before {
          background-image: url('~@/assets/gxImg/home/arrows2.png');
        }
      }
    }
  }

  .right-main {
    overflow: hidden;
    flex: 1;
    height: 100%;
  }

  .scroll-view {
    overflow: hidden auto;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .list-item {
      width: calc((100% - 30px) / 3);
      background-color: #fefefe;
      padding: 24px 30px;
      box-sizing: border-box;
      margin: 0 15px 15px 0;

      &:nth-of-type(3n) {
        margin-right: 0;
      }

      .logo {
        height: 50px;
        text-align: center;
        margin-bottom: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .text {
        word-break: break-all;
        width: 100%;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 1.3;
        margin-bottom: 10px;

        a {
          color: #333333;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
